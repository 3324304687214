



import { Component, Vue } from "vue-property-decorator";

import api from "@/api";
@Component({
  name: "PageAutologin",
})
export default class extends Vue {
  created() {
    const loading = this.$loading({
      lock: true,
      text: "自动登录中",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.7)",
    });
    let token = this.$route.query.token as string;
    if (!token) {
      this.$router.replace({ path: "/" });
      return;
    }

    api.TYLOGIN({
      token: token,
    }).then((data) => {
      loading.close();
      sessionStorage.setItem("phone", data.user.username);
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("system", "17");
      location.href = "/#/";
    });
  }
}
